<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
<!--    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">-->
<!--      <dark-Toggler class="d-none d-lg-block" />-->
<!--    </div>-->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <span v-if="profile.role.name === 'user'" class="mr-2">
        +998 99 888 51 80
      </span>
      <Notification :paragraphs="paragraphs" />
      <b-nav-item-dropdown
          id="dropdown-grouped"
          variant="link"
          class="dropdown-language"
          right
      >
        <template #button-content>
          <b-img
              :src="currentLocale.img"
              height="14px"
              width="22px"
              :alt="currentLocale.locale"
          />
          <span class="ml-50 text-body">{{ currentLocale.name }}</span>
        </template>
        <b-dropdown-item
            v-for="localeObj in locales"
            :key="localeObj.locale"
            @click="changeLocale(localeObj.locale)"
        >
          <b-img
              :src="localeObj.img"
              height="14px"
              width="22px"
              :alt="localeObj.locale"
          />
          <span class="ml-50">{{ localeObj.name }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ profile.firstname }}
            </p>
            <p>{{ profile.role.name | firstUpper }}</p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logoutUser"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('Logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
// eslint-disable-next-line no-unused-vars
import { getUserData, setUserLang } from '../../libs/auth'
import Notification from '@/layouts/components/Notification'
import { getUserNotifications } from '@/api/users'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    Notification,

    // Navbar Components
    DarkToggler,
  },
  data() {
    return {
      paragraphs: [],
      locales: [
        {
          locale: 'uz',
          img: require('@/assets/images/flags/uz.jpg'),
          name: "O'zbekcha"
        },
        {
          locale: 'ru',
          img: require('@/assets/images/flags/ru.jpg'),
          name: 'Русский',
        },
        {
          locale: 'en',
          img: require('@/assets/images/flags/en.png'),
          name: 'English',
        },
      ]
    }
  },

  created() {
    this.fetchNotifications();
  },
  filters: {
    firstUpper(value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
      profile() {
      return getUserData().userData
    },
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  methods: {
    logoutUser() {
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('auth/logoutTheUser').then(res => {
        this.$router.push('/login')
      })
    },
    changeLocale(locale) {
      setUserLang(locale);
      this.$i18n.locale = locale;
      location.reload();
      // changeUserLocale({lang:locale}).then(res => {
      //
      //   let user = getUserData()
      //   user.lang = res.data.data;
      //   setUserData(user);
      // })
    },
    fetchNotifications() {
      getUserNotifications().then(res => {
        this.paragraphs = res.data.data;
      })
    },
  },
}
</script>
