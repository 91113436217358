export default [
  {
    title: 'Home',
    i18n: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'DocCategory',
    i18n: 'DocCategory',
    route: 'doccategory',
    icon: 'FileIcon',
    roles: ['moderator'],
  },
  {
    title: 'Documents',
    i18n: 'Documents',
    route: 'documents',
    icon: 'FileIcon',
    roles: ['moderator'],
  },
  {
    title: 'Directory',
    i18n: 'Directory',
    route: 'directory',
    icon: 'FileIcon',
    roles: ['moderator'],
  },
  {
    title: 'Users',
    i18n: 'Users',
    route: 'users',
    icon: 'FileIcon',
    roles: ['moderator'],
  },
  {
    title: 'Years',
    i18n: 'Years',
    route: 'years',
    icon: 'FileIcon',
    roles: ['moderator'],
  },
  {
    title: 'HomePage',
    i18n: 'HomePage',
    route: 'userpage',
    icon: 'HomeIcon',
    roles: ['user'],
  },
  {
    title: 'Назначенние на меня пункты',
    i18n: 'CategoryName',
    route: 'seconduser',
    icon: 'HomeIcon',
    roles: ['user'],
  },
  // {
  //   roles: ['user'],
  //   title: 'Home',
  //   route: 'home',
  //   icon: 'HomeIcon',
  // },
  // {
  //   title: 'Second Page',
  //   route: 'second-page',
  //   icon: 'FileIcon',
  //   roles: ['user'],
  // },
  // {
  //   title: '2021',
  //   route: 'page-2021',
  //   icon: 'FileIcon',
  //   roles: ['user'],
  // },
  // {
  //   title: 'УП-6195',
  //   route: 'completed',
  //   icon: 'FileIcon',
  //   roles: ['user'],
  // },
  // {
  //   title: 'SubCompleted',
  //   route: 'subcompleted',
  //   icon: 'FileIcon',
  //   roles: ['user'],
  // },
  // {
  //   title: 'Пункт 1',
  //   route: 'punktone',
  //   icon: 'FileIcon',
  //   roles: ['user'],
  // },
]
